body {
    width:100%;
}

.hidden{
  display:none;
}
/* for preloading documents */
[v-cloak] {
  display:none;
}

.errors {
  color:red;
}

/* loading */
/* https://epic-spinners.epicmax.co/#/ */
.semipolar-spinner, .semipolar-spinner * {
  box-sizing: border-box;
}

.semipolar-spinner {
  margin: auto;
  width: 50%;
  height: 65px;
  width: 65px;
  position: relative;
}

.semipolar-spinner .ring {
  border-radius: 50%;
  position: absolute;
  border: calc(65px * 0.05) solid transparent;
  border-top-color: #ff1d5e;
  border-left-color: #ff1d5e;
  animation: semipolar-spinner-animation 2s infinite;
}

.semipolar-spinner .ring:nth-child(1) {
  height: calc(65px - 65px * 0.2 * 0);
  width: calc(65px - 65px * 0.2 * 0);
  top: calc(65px * 0.1 * 0);
  left: calc(65px * 0.1 * 0);
  animation-delay: calc(2000ms * 0.1 * 4);
  z-index: 5;
}

.semipolar-spinner .ring:nth-child(2) {
  height: calc(65px - 65px * 0.2 * 1);
  width: calc(65px - 65px * 0.2 * 1);
  top: calc(65px * 0.1 * 1);
  left: calc(65px * 0.1 * 1);
  animation-delay: calc(2000ms * 0.1 * 3);
  z-index: 4;
}

.semipolar-spinner .ring:nth-child(3) {
  height: calc(65px - 65px * 0.2 * 2);
  width: calc(65px - 65px * 0.2 * 2);
  top: calc(65px * 0.1 * 2);
  left: calc(65px * 0.1 * 2);
  animation-delay: calc(2000ms * 0.1 * 2);
  z-index: 3;
}

.semipolar-spinner .ring:nth-child(4) {
  height: calc(65px - 65px * 0.2 * 3);
  width: calc(65px - 65px * 0.2 * 3);
  top: calc(65px * 0.1 * 3);
  left: calc(65px * 0.1 * 3);
  animation-delay: calc(2000ms * 0.1 * 1);
  z-index: 2;
}

.semipolar-spinner .ring:nth-child(5) {
  height: calc(65px - 65px * 0.2 * 4);
  width: calc(65px - 65px * 0.2 * 4);
  top: calc(65px * 0.1 * 4);
  left: calc(65px * 0.1 * 4);
  animation-delay: calc(2000ms * 0.1 * 0);
  z-index: 1;
}

@keyframes semipolar-spinner-animation {
  50% {
    transform: rotate(360deg) scale(0.7);
  }
}
/* end loading */
@media only screen and (max-width: 600px) {
    #desktop{
        display: none;
    }
    body{
      text-align: center;

    }

    #m-content{
      /* border: 1px solid black; */
      display: inline-block;
    }

    .formInput{
        text-align: left;
        margin-left: 5px 5px;
    }

  /* .formInput > label{
    text-align: left;
    } */
}

/* may remove when doing different desktop */
#desktop{
  display: none;
}
body{
  text-align: center;

}

#m-content{
  /* border: 1px solid black; */
  display: inline-block;
}

.formInput{
    text-align: left;
    margin-left: 5px 5px;
}
/* end all */

@media only screen and (min-width: 601px) and (max-width: 1px) {
    #mobile{
        display: none;
    }
}

.button {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
  }

.round {
  border-radius: 12px;
}